import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import Link from "gatsby-link";

import styles from "./new-years-menu.module.scss";

export default ({ data }) => {
  return (
    <Layout>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>New Year's Eve Menu | {data.site.siteMetadata.title}</title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content="Join us for New Year's Eve at Fume RestoBar in Holmedale in Brantford!" />
        <meta property="og:title" content={`Menu | ${data.site.siteMetadata.title}`} />
        <meta property="og:description" content="Join us for New Year's Eve at Fume RestoBar in Holmedale in Brantford!" />
        <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        <meta property="og:image" content={`${data.site.siteMetadata.siteUrl}/icons/icon-512x512.png`} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={`Menu | ${data.site.siteMetadata.title}`} />
        <meta name="twitter:image" content={`${data.site.siteMetadata.siteUrl}/icons/icon-512x512.png`} />
      </Helmet>

      {data.allWordpressPage.edges.map(({ node }) => (
        <div className={styles.menu}>
          <h1>{node.title}</h1>
          <div className={styles.content} dangerouslySetInnerHTML={{__html: node.content }} />
        </div>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,
        desc,
        siteUrl
      }
    },
    allWordpressPage(
      filter: { slug:{ eq: "new-years-eve-menu" }}
    ) {
       edges {
         node {
          title,
          slug,
          content
        }
      }
    }
  }
`
